<template>
  <div class="hold-transition">
    <div class="wrapper">
      <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Tarifas Adicionales</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Hidrocarburos</li>
                  <li class="breadcrumb-item active">Administración</li>
                  <li class="breadcrumb-item active">Gestión Contratos</li>
                  <li class="breadcrumb-item active">Tarifas Adicionales</li>
                </ol>
              </div>
            </div>
          </div>
          <!-- /.container-fluid -->
        </section>
        <!-- Main content -->
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2">
                <div class="row">
                  <div class="col-md-12">
                    <div class="btn-group float-right">
                      <button
                        type="button"
                        class="btn btn-primary"
                        data-toggle="modal"
                        data-target="#modal-form-tarifas"
                        v-if="
                          $store.getters.can(
                            'hidrocarburos.tarifasAdicionales.index'
                          )
                        "
                        @click="abrirModal('Crear', null)"
                      >
                        <i class="fas fa-plus"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body p-0">
                <table
                  id="users"
                  class="table table-bordered table-striped table-hover table-sm"
                >
                  <thead>
                    <tr>
                      <th>
                        Tipo de Vehículo
                        <select
                          class="form-control form-control-sm"
                          v-model="filtros.tipo_vehiculo_id"
                          @change="getIndex()"
                        >
                          <option value="">Seleccione...</option>
                          <option
                            v-for="tipo_vehiculo in arrListasForm.tipos_vehiculos"
                            :key="tipo_vehiculo.id"
                            :value="tipo_vehiculo.id"
                          >
                            {{ tipo_vehiculo.nombre }}
                          </option>
                        </select>
                      </th>
                      <th class="text-center">Tarifa día Operativo</th>
                      <th class="text-center">Tarifa hora</th>
                      <th>
                        Estado
                        <select
                          class="form-control form-control-sm"
                          v-model="filtros.estado"
                          @change="getIndex()"
                        >
                          <option value="">Todos...</option>
                          <option
                            v-for="estado in arrListasForm.estados"
                            :key="estado.numeracion"
                            :value="estado.numeracion"
                          >
                            {{ estado.descripcion }}
                          </option>
                        </select>
                      </th>
                      <th class="text-center">Acción</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="tarifa_adicional in tarifas_adicionales.data"
                      :key="tarifa_adicional.id"
                    >
                      <td>{{ tarifa_adicional.tipo_vehiculo.nombre }}</td>
                      <td>{{ tarifa_adicional.tarifa_dia_operacion }}</td>
                      <td>{{ tarifa_adicional.tarifa_hora }}</td>
                      <td class="text-center">
                        <span
                          class="badge"
                          v-bind:class="[
                            tarifa_adicional.estado == 1
                              ? 'badge-success'
                              : 'badge-danger',
                          ]"
                        >
                          {{
                            tarifa_adicional.estado == 1 ? "ACTIVO" : "INACTIVO"
                          }}
                        </span>
                      </td>
                      <td style="width: 50px">
                        <div class="btn-group float-right">
                          <button
                            type="button"
                            class="btn btn-sm bg-navy"
                            data-toggle="modal"
                            data-target="#modal-form-tarifas"
                            v-if="
                              $store.getters.can(
                                'hidrocarburos.tarifasAdicionales.edit'
                              )
                            "
                            @click="abrirModal('Editar', tarifa_adicional.id)"
                          >
                            <i class="fas fa-edit"></i>
                          </button>
                          <button
                            type="button"
                            class="btn btn-sm btn-danger"
                            v-if="
                              $store.getters.can(
                                'hidrocarburos.tarifasAdicionales.delete'
                              )
                            "
                            @click="destroy(tarifa_adicional.id)"
                          >
                            <i class="fas fa-trash-alt"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="card-footer">
                <div class="float-left" v-if="tarifas_adicionales.total">
                  <p>
                    Mostrando del <b>{{ tarifas_adicionales.from }}</b> al
                    <b>{{ tarifas_adicionales.to }}</b> de un total:
                    <b>{{ tarifas_adicionales.total }}</b> Registros
                  </p>
                </div>
                <div class="float-left" v-else>
                  <p>
                    <span class="badge badge-danger">
                      No hay registros para mostrar
                    </span>
                  </p>
                </div>
                <pagination
                  :data="tarifas_adicionales"
                  @pagination-change-page="getIndex"
                  :limit="3"
                  align="right"
                ></pagination>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <!-- Modal -->
    <div class="modal fade" id="modal-form-tarifas">
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">{{ modal.title }}</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label for="tipo_vehiculo_id">Tipo de Vehículo</label>
              <select
                class="form-control form-control-sm"
                v-model="objTarifaAdicional.tipo_vehiculo_id"
                :class="
                  $v.objTarifaAdicional.tipo_vehiculo_id.$invalid
                    ? 'is-invalid'
                    : 'is-valid'
                "
              >
                <option value="">Seleccione...</option>
                <option
                  v-for="tipo_vehiculo in arrListasForm.tipos_vehiculos"
                  :key="tipo_vehiculo.id"
                  :value="tipo_vehiculo.id"
                >
                  {{ tipo_vehiculo.nombre }}
                </option>
              </select>
            </div>
            <div class="form-group">
              <label for="tarifa_dia_operacion">Tarifa día Operación</label>
              <input
                type="number"
                min="0"
                class="form-control form-control-sm"
                placeholder="Tarifa día Operación"
                v-model="objTarifaAdicional.tarifa_dia_operacion"
                :class="
                  $v.objTarifaAdicional.tarifa_dia_operacion.$invalid
                    ? 'is-invalid'
                    : 'is-valid'
                "
              />
            </div>
            <div class="form-group">
              <label for="tarifa_hora">Tarifa Hora Adicional</label>
              <input
                type="number"
                min="0"
                class="form-control form-control-sm"
                placeholder="Tarifa Hora Adicional"
                v-model="objTarifaAdicional.tarifa_hora"
                :class="
                  $v.objTarifaAdicional.tarifa_hora.$invalid
                    ? 'is-invalid'
                    : 'is-valid'
                "
              />
            </div>
            <div class="form-group">
              <label for="estado">Estado</label>
              <select
                class="form-control form-control-sm"
                v-model="objTarifaAdicional.estado"
                :class="
                  $v.objTarifaAdicional.estado.$invalid
                    ? 'is-invalid'
                    : 'is-valid'
                "
              >
                <option value="">Seleccione...</option>
                <option
                  v-for="estado in arrListasForm.estados"
                  :key="estado.id"
                  :value="estado.id"
                >
                  {{ estado.descripcion }}
                </option>
              </select>
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <button
              type="button"
              class="btn btn-primary"
              v-if="
                $store.getters.can('hidrocarburos.tarifasAdicionales.create') ||
                $store.getters.can('hidrocarburos.tarifasAdicionales.show')
              "
              v-show="!$v.objTarifaAdicional.$invalid"
              @click="save()"
            >
              Guardar
            </button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required } from "vuelidate/lib/validators";
import pagination from "laravel-vue-pagination";

export default {
  name: "TarifaAdicionalIndex",
  components: {
    pagination,
  },
  data() {
    return {
      tarifas_adicionales: {},
      objTarifaAdicional: {
          id: null,
          tipo_vehiculo_id: null,
          tarifa_dia_operacion: null,
          tarifa_hora: null,
          estado: null,
      },
      filtros: {
        tipo_vehiculo_id: null,
        estado: null,
      },
      arrListasForm: {
        tipos_vehiculos: [],
        estados: [],
      },
      modal: {
        title: "",
        accion: "",
      },
    };
  },
  validations: {
    objTarifaAdicional: {
      tipo_vehiculo_id: {
        required,
      },
      tarifa_dia_operacion: {
        required,
      },
      tarifa_hora: {
        required,
      },
      estado: {
        required,
      },
    },
  },
  methods: {
    getIndex(page = 1) {
      axios
        .get(
          "/api/hidrocarburos/administrativo/tarifasAdicionales?page=" + page,
          {
            params: this.filtros,
          }
        )
        .then((response) => {
          this.tarifas_adicionales = response.data;
        });
    },

    getTiposVehiculos() {
      /***
       * Se filtra el tipo de vehículos a mostrar
       * 1: CABEZOTE
       * 6: DOBLETROQUE
       */
      const in_id = [1, 6];
      const params = {
        in_id,
      };
      axios
        .get("/api/admin/tiposVehiculos/lista", {
          params,
        })
        .then((response) => {
          this.arrListasForm.tipos_vehiculos = response.data;
        });
    },

    getEstados() {
      axios.get("/api/lista/1").then((response) => {
        this.arrListasForm.estados = response.data;
      });
    },

    abrirModal(accion, idTarifaAdicional) {
      this.modal.accion = accion;
      this.modal.title = accion + " Tarifa Adicional";
      if (accion == "Editar") {
        this.show(idTarifaAdicional);
      } else {
        this.objTarifaAdicional = {};
      }
    },

    save() {
      if (!this.$v.objTarifaAdicional.$invalid) {
        axios
          .post(
            "/api/hidrocarburos/administrativo/tarifasAdicionales",
            this.objTarifaAdicional
          )
          .then((response) => {
            this.$refs.closeModal.click();
            this.objTarifaAdicional = {};
            this.getIndex();
            this.$swal({
              icon: "success",
              title: "Se guardo exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          })
          .catch((e) => {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error, vualeva a intentarlo..." + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    show(idTarifaAdicional) {
      axios
        .get(
          "/api/hidrocarburos/administrativo/tarifasAdicionales/show/" +
            idTarifaAdicional
        )
        .then((response) => {
          this.objTarifaAdicional = response.data[0];
        });
    },

    destroy(idTarifaAdicional) {
      this.$swal({
        title: "Esta seguro de eliminar este Tarifa Adicional?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        // <--
        if (result.value) {
          axios
            .delete(
              "/api/hidrocarburos/administrativo/tarifasAdicionales/" +
                idTarifaAdicional
            )
            .then((response) => {
              this.getIndex();
              this.$swal({
                icon: "success",
                title: "Se eliminó la tarifa adicional exitosamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            });
        }
      });
    },
  },
  mounted() {
    this.getEstados();
    this.getTiposVehiculos();
    this.getIndex();
  },
};
</script>